import { template as template_e5196509e0014c1b9ff50e5a72460d84 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e5196509e0014c1b9ff50e5a72460d84(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
